import React from "react"
import udinus from "../../assets/images/partner/udinus.png"
import dikti from "../../assets/images/partner/dikti.png"
import kedaireka from "../../assets/images/partner/kedaireka.png"
import nvidia from "../../assets/images/partner/nvidia.png"
import pemkotSMG from "../../assets/images/partner/logo_pemkot_semarang.png"
import ramani from "../../assets/images/partner/ramani.png"
import epsiondo from "../../assets/images/partner/epsindo.png"
import indocircularwaste from "../../assets/images/partner/indocircularwaste.png"
import bima from "../../assets/images/partner/bima.png"
import iteba from "../../assets/images/partner/iteba.png"
import aspoo from "../../assets/images/partner/aspoo.png"
import dinkes from "../../assets/images/partner/DINKES.png"
import iarc from "../../assets/images/partner/IARC.png"
import ucm from "../../assets/images/partner/UCM.png"
import univTokyo from "../../assets/images/partner/UnivTokyo.png"
import nus from "../../assets/images/partner/nus.png"
import uinjakarta from "../../assets/images/partner/uin.png"
import ui from "../../assets/images/partner/ui.png"
import isisurakarta from "../../assets/images/partner/isi-surakarta.png"
import abmi from "../../assets/images/partner/abmi.png"
import sdu from "../../assets/images/partner/sdu.png"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const univ = [
  {
    img: udinus,
    link: "https://www.dinus.ac.id/",
  },
  {
    img: uinjakarta,
    link: "https://uinjkt.ac.id/id",
  },
  {
    img: ui,
    link: "https://www.ui.ac.id/",
  },
  {
    img: isisurakarta,
    link: "https://isi-ska.ac.id/",
  },
  {
    img: univTokyo,
    link: "https://www.u-tokyo.ac.jp/en/",
  },
  {
    img: ucm,
    link: "https://www.cmu.ac.th/en/",
  },
  {
    img: iteba,
    link: "https://iteba.ac.id/",
  },
  {
    img: nus,
    link: "https://nus.edu.sg/",
  },
  {
    img: sdu,
    link: "https://www.sdu.dk/en",
  },
]
const industri = [
  {
    img: epsiondo,
    link: "https://www.epsindo.co.id/",
  },
  {
    img: nvidia,
    link: "https://www.nvidia.com/en-us/",
  },
  {
    img: ramani,
    link: "https://ramani.ujuizi.com/",
  },
]
const asosiasi = [
  {
    img: aspoo,
    link: "https://www.aspoojateng.com/",
  },
  {
    img: abmi,
    link: "",
  },
  {
    img: iarc,
    link: "https://www.ai-dikti.id/",
  },
  {
    img: indocircularwaste,
    link: "https://indocircularwaste.org/",
  },
]
const pemerintah = [
  {
    img: dikti,
    link: "https://www.kemdikbud.go.id/",
  },
  {
    img: kedaireka,
    link: "https://kedaireka.id/",
  },
  {
    img: pemkotSMG,
    link: "https://semarangkota.go.id/",
  },
  {
    img: dinkes,
    link: "https://dinkes.semarangkota.go.id/",
  },
  {
    img: bima,
    link: "https://bima.kemdikbud.go.id/",
  },
]

const Partner = () => {
  return (
    <section className="partner-area ptb-70 bg-fafafb">
      <div className="container">
        <div className="section-title">
          <h2>Partner</h2>
        </div>
        <div className="container">
          <Row>
            <Col className="mb-4" lg={6}>
              <Card className="shadow h-100">
                <Card.Body className="text-center">
                  <Card.Title>Universitas</Card.Title>
                  <hr />
                  <div className="d-flex flex-wrap col-lg-12">
                    <Row>
                      {univ.map((lg, idx) => (
                        <Col className="mt-2" lg={3} id={idx}>
                          {/* <div className="single-partner-item"> */}
                          <a
                            href={lg.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={lg.img}
                              alt="partner"
                              style={{ maxWidth: "80%", height: "auto" }}
                            />
                          </a>
                          {/* </divc> */}
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col className="mb-4" lg={6}>
              <Card className="shadow h-100">
                <Card.Body className="text-center">
                  <Card.Title>Pemerintah</Card.Title>
                  <hr />
                  <div className="d-flex flex-wrap col-lg-12">
                    <Row>
                      {pemerintah.map((lg, idx) => (
                        <Col className="mt-2" lg={3} id={idx}>
                          {/* <div className="single-partner-item"> */}
                          <a
                            href={lg.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={lg.img}
                              alt="partner"
                              style={{ maxWidth: "80%", height: "auto" }}
                            />
                          </a>
                          {/* </divc> */}
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6}>
              <Card className="shadow h-100">
                <Card.Body className="text-center">
                  <Card.Title>Industri</Card.Title>
                  <hr />
                  <div className="d-flex flex-wrap col-lg-12">
                    <Row>
                      {industri.map((lg, idx) => (
                        <Col className="mt-2" lg={3} id={idx}>
                          {/* <div className="single-partner-item"> */}
                          <a
                            href={lg.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={lg.img}
                              alt="partner"
                              style={{ maxWidth: "80%", height: "auto" }}
                            />
                          </a>
                          {/* </divc> */}
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6}>
              <Card className="shadow h-100">
                <Card.Body className="text-center">
                  <Card.Title>Asosiasi</Card.Title>
                  <hr />
                  <div className="d-flex flex-wrap col-lg-12">
                    <Row>
                      {asosiasi.map((lg, idx) => (
                        <Col className="mt-2" lg={3} id={idx}>
                          {/* <div className="single-partner-item"> */}
                          <a
                            href={lg.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={lg.img}
                              alt="partner"
                              style={{ maxWidth: "80%", height: "auto" }}
                            />
                          </a>
                          {/* </divc> */}
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  )
}

export default Partner